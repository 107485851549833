import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { Highlights } from '@common/pages'

const query = graphql`
  query Highlights {
    allArticle(
      filter: {
        tags: {
          elemMatch: { slug: { in: ["anxiety", "chronic-pain", "sleep"] } }
        }
        belongsTo: { in: ["CA"] }
      }
      sort: { fields: [publishedAt], order: DESC }
      limit: 200
    ) {
      edges {
        node {
          id
          migrationId
          title
          slug
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          tags {
            id
            name
            slug
          }
          childMarkdownRemark {
            excerpt(pruneLength: 80)
          }
        }
      }
    }
  }
`

const HighlightsPage = () => {
  const {
    allArticle: { edges: articles },
  } = useStaticQuery(query)

  return <Highlights articles={articles} />
}

export default HighlightsPage
